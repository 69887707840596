import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { Link } from 'gatsby'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import BlockContent from '../block-content'
import RoleList from '../role-list/role-list'
import BlogPostTags from '../blog-tags/blog-post-tags'

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

function Project (props) {
  const { _rawBody, title, _rawCategories,categories, mainImage, members, publishedAt, relatedProjects, startedAt, endedAt } = props
  return (
    <>
    <div className={'page-header page-header-image--background'}>
      {mainImage && mainImage.asset && (
        <Row
          style={{ 
            backgroundImage: `url(${(/https\:\/\/|http\:\/\//g).test(mainImage.asset) ? mainImage.asset : imageUrlFor(buildImageObj(mainImage)).url()})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            width: '100vw',
            textAlign: 'center',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Container
            style={{
              maxHeight: 300
            }}>
              <h1 class="title">{title}</h1>
              {
                props.authors && (
                  <div className="author">
                    <div style={{
                      display: 'flex',
                      flexFlow: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                      {
                        props.authors[0].person.image &&
                        (<img src={imageUrlFor(buildImageObj(props.authors[0].person.image)).url()} alt={props.authors[0].person.name} class="avatar img-raised" />)
                      }
                      {
                        props.authors[0].person.name && (
                        <h4 className={'description'}>{props.authors[0].person.name}</h4>
                        )
                      }
                    </div>
                  </div>
                )
              }
          </Container>
        </Row>
      )}
      </div>
      <Container>
      <Row>
        <Col md={8} className={'mr-auto ml-auto'}>
          <Row>
            <Col md={12} style={{paddingTop: 30}}>
              <h2 class="category" style={{fontSize: '27px'}}>
                  {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), 'MMMM Do YYYY')}
              </h2><br />
              {_rawBody && <BlockContent blocks={_rawBody} />}
            </Col>
          </Row>
          <Row>
            <Col md={12} style={{paddingTop: 30}}>
              <h2 class="category" style={{fontSize: '16px'}}>
                  {differenceInDays(new Date(startedAt), new Date()) > 3
                  ? `Start Date: ${distanceInWords(new Date(startedAt), new Date())}`
                  : `Start Date: ${format(new Date(startedAt), 'MM/DD/YY')}`}
                  {differenceInDays(new Date(endedAt), new Date()) > 3
                  ? ` - End Date: ${distanceInWords(new Date(endedAt), new Date())}`
                  : ` - End Date: ${format(new Date(endedAt), 'MM/DD/YY')}`}
              </h2>
              {_rawCategories && (
                <BlogPostTags categories={_rawCategories} label/>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
            {/* {relatedProjects && (
              <div className={styles.relatedProjects}>
                <h3 className={styles.relatedProjectsHeadline}>Related projects</h3>
                <ul>
                  {relatedProjects.map(project => (
                    <li key={`related_${project._id}`}>
                      <Link to={`/project/${project.slug.current}`}>{project.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            )} */}
    </>
  )
}

export default Project
